<template>
  <div class="main-login-block center-block">
    <div>
      <div class="logo" v-html="Logotype"></div>
      <h3 class="title">Change password</h3>

      <div class="form-row">
        <div class="w-100">
          <input-password v-model="password" />
          <div v-if="errorPassword" class="error">{{ errorPassword }}</div>
        </div>
      </div>

      <div class="form-row">
        <div class="w-100">
          <input-password v-model="passwordConfirm" />
          <div class="error">{{ errorPasswordConfirm }}</div>
        </div>
      </div>

      <div class="form-row" v-if="response">
        <div class="error server">
          {{ response }}
        </div>
      </div>

      <div class="form-row">
        <div class="main-button" style="width: 100%" @click="onSet">
          Change my password
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Logotype } from '@/utils/icons'
import InputPassword from '@/components/inputs/InputPassword'
import { urls } from '@/utils/urls.js'

export default {
  name: 'ClientContactSetPassword',
  components: { InputPassword },

  data() {
    return {
      Logotype,
      password: {
        placeholder: 'New password',
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        readonly: false,
        // maxlength: 5,
        minlength: 8,
      },

      passwordConfirm: {
        placeholder: 'Confirm password',
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        readonly: false,
        // maxlength: 5,
        minlength: 8,
      },

      isDataLoaded: true,
      errorPasswordConfirm: null,
      errorPassword: null,
      response: null,
      token: null,
    }
  },

  computed: {
    isValidForm() {
      if (
        this.passwordConfirm.isValid &&
        this.password.isValid &&
        this.passwordConfirm.value === this.password.value
      )
        return true

      return false
    },
  },

  watch: {
    'passwordConfirm.value'() {
      this.$nextTick(() => {
        if (this.passwordConfirm.value !== this.password.value) {
          this.passwordConfirm.isValid = false
        } else if (
          this.password.isValid &&
          this.passwordConfirm.value === this.password.value
        ) {
          this.passwordConfirm.isValid = true
        }
      })
    },

    'password.value'() {
      this.$nextTick(() => {
        if (this.passwordConfirm.value !== this.password.value) {
          this.passwordConfirm.isValid = false
        } else if (
          this.password.isValid &&
          this.passwordConfirm.value === this.password.value
        ) {
          this.passwordConfirm.isValid = true
        }
      })
    },
  },

  created() {
    this.token = this.$route.query?.token || ''
  },

  methods: {
    async fetchSet(body) {
      if (!body || !this.isDataLoaded) return

      this.isDataLoaded = false

      try {
        let requestData = {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        }

        const response = await fetch(urls.clientContactsSetPassword, requestData)

        const json = await response.json()
        if (response.status === 200) {
          if (typeof json === 'object' && 'id' in json)
            this.$router.push('/clients')
        } else if (response.status > 399) {
          if (json?.detail) {
            this.response = json?.detail
          }
        }
        this.isDataLoaded = true
      } catch (error) {
        this.isDataLoaded = true
        console.log(error)
      }
    },

    onSet() {
      this.showErrors()
      if (this.isValidForm)
        this.fetchSet({ token: this.token, password: this.password.value })
    },

    showErrors() {
      if (!this.password.isValid) {
        if (this.password.errorKey === 'required')
          this.errorPassword = 'Please enter your password'
        else
          this.errorPassword =
            'Password must be at least 8 characters long and must be different from email'
      } else this.errorPasswordConfirm = null

      if (!this.passwordConfirm.isValid) {
        if (this.passwordConfirm.errorKey === 'required')
          this.errorPasswordConfirm = 'Please confirm your password'
        else
          this.errorPasswordConfirm =
            'Password must be at least 8 characters long and must be different from email'
      } else this.errorPasswordConfirm = null
    },
  },
}
</script>
